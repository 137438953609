(function () {
  if (!window.kitty) {
    window.kitty = {};
  }

  window.kitty.menuToggle = function (menu) {
    var useBackdrop = menu.dataset.backdrop == "true";
    if (useBackdrop) {
      if (!menu.classList.contains("show")) {
        var backdropElement = document.createElement("div");
        backdropElement.className = "kitty-backdrop";
        backdropElement.onclick = () => {
          backdropElement.parentNode.removeChild(backdropElement);
          menu.classList.remove("show");
          var button = menu.querySelector(".button");
          if (button) {
            button.contentDocument.querySelector(":root").classList.remove("active");
          }
        };
        document.body.prepend(backdropElement);
      } else {
        document.querySelectorAll("body > .kitty-backdrop").forEach((element) => {
          element.parentElement.removeChild(element);
        });
      }
    }

    menu.classList.toggle("show");
  };

  window.kitty.submenuToggle = function (button, submenu) {
    var isShowing = submenu.classList.contains("show");
    submenu.parentNode.querySelectorAll(".submenu").forEach((element) => {
      element.classList.remove("show");
    });
    if (isShowing) {
      submenu.classList.remove("show");
      button.classList.remove("show");
    } else {
      submenu.classList.add("show");
      button.classList.add("show");
    }
  };

  window.kitty.toggleMenuById = function (id, show) {
    var element = document.getElementById(id);
    if (element) {
      if (show) {
        element.parentNode.classList.remove("show");
        window.kitty.menuToggle(element.parentNode);
      } else {
        element.parentNode.classList.add("show");
        window.kitty.menuToggle(element.parentNode);
      }
    }
  };
  var prevScrollpos = Math.max(window.scrollY, 0);
  window.onscroll = function() {
    var currentScrollPos = Math.max(window.scrollY, 0);
    var menuActive = document.getElementsByClassName("kitty-menu menu-hamburger show").length;

    if (menuActive) {
      return;
    }
    if ((prevScrollpos === 0 && currentScrollPos === 0) || prevScrollpos > currentScrollPos) {
      document.getElementById("menu-container").style.top = "0px";
    } else {
      document.getElementById("menu-container").style.top = "-80px";
    }
    prevScrollpos = currentScrollPos;
  };

  document.querySelectorAll(".kitty-menu.menu-hamburger .submenu-toggle").forEach((toggleElement) => {
    if (!toggleElement.parentNode.nextSibling || !toggleElement.parentNode.nextSibling.classList.contains("submenu")) {
      toggleElement.parentNode.removeChild(toggleElement);
    }
  });
})();
